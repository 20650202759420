<template>
  <div>
    <div class="row">
      <div class="col-lg-4">
        Search By:
        <select v-model="filterType" class="form-control"
          ><option value="streetName">StreetName</option
          ><option value="id">Client ID</option></select
        ><br />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <input
          name="clientIdSearch"
          id="client-id-input"
          v-model="filterInput"
          :type="filterType == 'id' ? 'number' : 'text'"
          @keyup="filterTable"
          class="form-control mb-4"
        />
      </div>
    </div>
    <b-table
      hover
      responsive
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      stacked="md"
      show-empty
      small
    >
      <template #cell(summary)="row">
        <button
          style="margin-left:5px;margin-top:5px;padding-right:16px;"
          class="btn purple-btn btn-shadow font-weight-bold col-12 col-lg-6"
          @click="navigateToDetails(row)"
        >
          Details
        </button>
        <a
          :href="
            reportBaseUrl + '/report/clientreport?code=' + row.item.reportCode
          "
          target="_blank"
        >
          <button
            class="btn btn-success btn-shadow col-12 col-lg-6"
            style="margin-top:5px;margin-left:5px;"
          >
            Report
          </button>
        </a>
      </template>
      <template #cell(updatedOn)="row">
        <p>{{ row.item.updatedOn.toLocaleDateString() }}</p>
      </template>
    </b-table>
    <div class="row">
      <div class="col-md-12">
        <a
          :style="
            currentPage == number
              ? 'cursor:pointer; font-size:20px;color:black !important;'
              : 'cursor:pointer;font-size:20px;'
          "
          v-for="number in pageNumbers"
          :key="number"
          class="text-primary"
          @click="setPage(number)"
          >{{ number }}&nbsp;&nbsp;</a
        >
      </div>
    </div>
    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { clientService, userService } from "../../_services";
import router from "../../router/index";
import { config } from "../../../config";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      reportBaseUrl: config.reportUrl,
      items: [],
      oldList: [],
      filteredItems: [],
      filterInput: "",
      filterType: "streetName",

      fields: [
        {
          key: "id",
          label: "Client ID",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "streetName",
          label: "Street Name",
          // eslint-disable-next-line no-unused-vars

          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },

        {
          key: "status",
          label: "Assessment Status",
          sortable: true,
          class: "text-center",
        },
        {
          key: "therapistName",
          label: "Conducted By",
          sortable: true,
          class: "text-center",
        },
        {
          key: "updatedOn",
          label: "Last Updated",
          sortable: true,
          class: "text-center",
        },
        { key: "summary", label: "" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: null,
      filterOn: [],
      pageNumbers: [],
    };
  },
  mounted() {
    this.isLoading = true;
    clientService.getAllClients().then(
      (response) => {
        for (var i = 0; i < response.data.length; i++) {
          switch (response.data[i].status) {
            case "Initial Assessment Started":
              response.data[i].status = "Initial Started";
              break;
            case "Initial Assessment Completed":
              response.data[i].status = "Initial Completed";
              break;
            case "Follow-Up Assessment Started":
              response.data[i].status = "Follow-Up Started";
              break;
            case "Follow-Up Assessment Completed":
              response.data[i].status = "Follow-Up Completed";
          }
          switch (response.data[i].gender) {
            case "female":
              response.data[i].gender = "F";
              break;
            case "male":
              response.data[i].gender = "M";
              break;
          }
          response.data[i].updatedOn = new Date(response.data[i].updatedOn);
        }
        this.isLoading = false;
        this.items = response.data;
        var totalItems = this.items.length;
        var pages = Math.ceil(totalItems / this.perPage);
        for (var j = 1; j <= pages; j++) {
          this.pageNumbers.push(j);
        }
        this.oldList = response.data;
      },
      () => {
        this.isLoading = false;
        userService.logout();
        this.$store.commit("setIsLoggedIn", false);
      }
    );
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  methods: {
    setPage: function(number) {
      this.currentPage = number;
    },
    navigateToDetails(row) {
      router.push({ name: "ClientDetails", params: { id: row.item.id } });
    },
    individualReport(row) {
      window.location.href = `https://hflweb.azurewebsites.net/report/clientreport?code=${row.item.reportCode}`;
    },
    filterTable() {
      if (this.filterInput == null || this.filterInput == "")
        this.items = this.oldList;
      else {
        var newList = [];
        for (var i = 0; i < this.items.length; i++) {
          if (this.filterType == "id") {
            if (String(this.items[i].id).includes(String(this.filterInput)))
              newList.push(this.items[i]);
          } else if (
            this.filterType == "streetName" &&
            this.items[i].streetName
          ) {
            if (
              String(this.items[i].streetName.toLowerCase()).includes(
                String(this.filterInput.toLowerCase())
              )
            ) {
              newList.push(this.items[i]);
            }
          }
        }
        this.items = newList;
      }
    },
  },
};
</script>
