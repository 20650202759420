<template>
  <div>
    <SecondNavBar />
    <main>
      <p style="text-align:center;" class="mt-4 mb-4">
        <img
          :src="organizationLogoLink"
          class="img-fluid col-md-2"
          alt="organization logo"
        />
      </p>
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="row" id="clienttable-header-row">
            <h4 style="padding-top:20px;padding-bottom:10px;">
              <strong>Client Listings</strong>
            </h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <router-link
            :to="{
              path: '/create-client'
            }"
          >
            <button class="btn purple-btn btn-shadow mb-2 font-weight-bold">
              <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;New Client
            </button>
          </router-link>
          <button
            style="float:right;"
            :disabled="disableDownloadButton"
            @click="downloadClientsReport()"
            class="btn ml-4 btn-warning mb-2 btn-shadow font-weight-bold"
          >
            <FontAwesomeIcon icon="file-download" />&nbsp;&nbsp;Export Client
            Info
          </button>
        </div>
      </div>
      <div class="row" v-show="showDownloadingMessage" style="mb-2 mt-2">
        <div class="col-md-8 offset-md-2">
          <p>
            Your report is being generated and will be automatically downloaded
            once it's complete.
          </p>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <ClientTable></ClientTable>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import ClientTable from "../../components/Clients/ClientTable";
import SecondNavBar from "../../components/Clients/SecondNavBar";
import { reportService } from "../../_services";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { clientService } from "../../_services/index";
// import axios from 'axios'

export default {
  components: {
    ClientTable,
    SecondNavBar,
    FontAwesomeIcon,
  },
  data() {
    return {
      showErrorMessage: false,
      showDownloadingMessage: false,
      disableDownloadButton: false,
      organizationLogoLink: ""
    };
  },
  mounted() {
    clientService.getOrganizationLogo().then((response) => {
      this.organizationLogoLink = response.data;
    });
  },
  methods: {
    downloadClientsReport() {
      this.disableDownloadButton = true;
      this.showDownloadingMessage = true;
      reportService
        .generateAllClientsReport()
        // show downloading message
        .then(
          (response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute(
              "download",
              reportService.getAllClientsReportName()
            );
            document.body.appendChild(fileLink);
            fileLink.click();
          },
          () => {
            this.disableDownloadButton = false;
            this.showDownloadingMessage = false;
            this.$toast.error("Failed to Generate Client Report.", {
              position: "bottom-center",
              timeout: 5000,
              hideProgressBar: true,
            });
          }
        );
    },
  },
};
</script>

<style>
#clienttable-header-row {
  /* background-color:#e4e4e4; */
  border-bottom: 3px solid #b4d88c;
  color: #6c207e !important;
  padding: 3px 10px 6px 12px;
  font-weight: bold;
  margin-bottom: 40px;
}

.btn-warning {
  color: white !important;
  font-weight: bold !important;
}

.btn-success {
  font-weight: bold !important;
}
</style>
